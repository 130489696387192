import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Link } from 'gatsby';

import Layout from '../layout';
import SEO from 'src/components/SEO';
import './ghost.css';

interface Props {
  data: {
    post: {
      html: string;
      title: string;
      published_at_pretty: string;
    };
    site: {
      siteMetadata: {
        title: string;
        description: string;
      };
    };
    readmore: {
      nodes: {
        title: string;
        feature_image: string;
        published_at: string;
        plaintext: string;
        ghostId: string;
      }[];
    };
  };
}

const Heading = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  marginBottom: 0,
  lineHeight: 1.2,

  [theme.breakpoints.up('md')]: {
    fontSize: 60,
  },
})) satisfies typeof Typography;

const DateText = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  color: '#b4b4b4',

  [theme.breakpoints.up('md')]: {
    fontSize: 26,
  },
})) satisfies typeof Typography;

const Content = styled('div')({
  color: '#222222',
});

const StyledLink = styled(Link)({
  textDecoration: 'none',
});

const BlogCard = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  marginBottom: 48,

  '&> img': {
    width: '100%',
    marginBottom: 16,
  },
  '& h3': {
    color: 'rgba(0, 0, 0, 0.85)',
    fontSize: 20,
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '& p': {
    color: '#4a4a4a',
    fontSize: 14,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const MoreButton = styled(Link)({
  padding: 16,
  display: 'flex',
  justifyContent: 'center',
  border: '2px solid #0d0d0d',
  color: '#262626',
  fontSize: 16,
  textDecoration: 'none',
});

const PostTemplate: FC<Props> = ({ data }) => {
  const {
    post,
    readmore: { nodes },
  } = data;

  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Layout>
      <Container>
        <Grid container spacing={8}>
          <Grid item xs={12} md={9}>
            <Heading variant="h1">{post.title}</Heading>
            <DateText variant="body1">{post.published_at_pretty}</DateText>
            <Content
              className={'content'}
              dangerouslySetInnerHTML={{ __html: post.html }}
            />
          </Grid>
          <Grid item md={3}>
            {!smDown && (
              <>
                {nodes.map(item => (
                  <StyledLink to={`/post/` + item.ghostId} key={item.ghostId}>
                    <BlogCard>
                      <img src={item.feature_image} alt={item.title} />
                      <Typography variant="h3">{item.title}</Typography>
                      <Typography variant="body1">
                        {item.plaintext.slice(0, 30)}... <br />
                      </Typography>
                      <DateText variant="body1">{item.published_at}</DateText>
                    </BlogCard>
                  </StyledLink>
                ))}
                <MoreButton to="/blog">More</MoreButton>
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};

export default PostTemplate;

export const postQuery = graphql`
  query($ghost_id: String, $ghost_category: String) {
    post: ghostPost(ghostId: { eq: $ghost_id }) {
      html
      title
      published_at_pretty: published_at(formatString: "YYYY.MM.DD")
    }
    site {
      siteMetadata {
        title
        description
      }
    }
    readmore: allGhostPost(
      limit: 3
      filter: { tags: { elemMatch: { name: { eq: $ghost_category } } } }
      sort: { order: DESC, fields: [published_at] }
    ) {
      nodes {
        title
        feature_image
        published_at(formatString: "YYYY.MM.DD")
        plaintext
        ghostId
      }
    }
  }
`;


export const Head = ({ data }) => {
  const { post } = data;

  return <SEO title={post.title} />
}